import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import { AboutPage } from "./routes/AboutPage";
import { StatementPage } from "./routes/StatementPage";
import Contact from "./routes/Contact";
import { ProjectsPage } from "./routes/ProjectsPage";
import { QCInfo } from "./routes/qcInfo";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/personal-statement",
    element: <StatementPage />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/work",
    element: <ProjectsPage />,
  },
  {
    path: "/qc",
    element: <QCInfo />,
  },
]);
root.render(
  <div className="full-page">
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
