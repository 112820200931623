import "../App.css";
import { imageObject } from "../routes/types";
import { ProgressiveRendering } from "./ProgressiveRendering";

interface ProjectPreviewProps {
  imageObject?: imageObject;
  title: string;
  description: string;
  url: string;
}

const defaultPhoto = "/hhkb-100px.png";

const redirectToWebsite = (url: string) => {
  window.open(url, "_blank");
};

export const ProjectPreview = ({
  imageObject,
  title,
  description,
  url,
}: ProjectPreviewProps) => {
  return (
    <div
      className="Project-list-item cursor-pointer"
      onClick={() => redirectToWebsite(url)}
    >
      <div className="projectListWrapper">
        <div className="Project-list-text-wrapper">
          <div className="Project-list-item-title">
            <samp>{title}</samp>
          </div>
          <div className="Project-list-item-description">
            <samp>{description}</samp>
          </div>
        </div>
        <div className="thin-border projectListImageWrapper maxWidth40">
          <ProgressiveRendering>
            <img
              src={imageObject ? imageObject.imageDir : defaultPhoto}
              alt={`${title}-preview`}
              className="project-photo"
            />
          </ProgressiveRendering>
        </div>
      </div>
    </div>
  );
};
