import "../App.css";
import { ProjectPreview } from "../components/ProjectPreview";
import { imageObject } from "./types";
import { BackButton } from "../components/BackButton";
import { NavBar } from "../components/NavBar";

type project = {
  imageObject?: imageObject;
  title: string;
  description: string;
  url: string;
};

const projects: project[] = [
  {
    imageObject: {
      imageDir: "/projects/qclip.png",
      width: 1250,
      height: 655,
    },
    title: "QClip",
    description:
      "University Admissions Chatbot; RAG model trained with Queens College data.",
    url: "https://qclip.study/",
  },
  {
    imageObject: {
      imageDir: "/projects/spaceq.png",
      width: 1250,
      height: 655,
    },
    title: "SpaceQueens - Brown University Hackathon 2024 Winner",
    description:
      "Education bundle -- In browser 3D rocket launch simulator -- Carbon emission and cost calculator -- AI Chatbot",
    url: "https://spaceq.tech/",
  },
  {
    imageObject: {
      imageDir: "/projects/qc-pc.png",
      width: 1250,
      height: 655,
    },
    title: "Queens College Physics Club Website",
    description:
      "Full-stack physics club website built primarily on Next.js and TypeScript;",
    url: "https://qc-physics-club-website.vercel.app/",
  },
  {
    title: "AMS Email Protocol",
    description:
      "End-to-end email system from scratch using Python’s low-level socket programming, referencing standard documentation and integrating open-source tools to implement POP3 and SMTP protocols;",
    url: "https://github.com/AdamJablonka/SMTP-POP3-Python",
  },
  {
    imageObject: {
      imageDir: "/projects/react-type.png",
      width: 1250,
      height: 655,
    },
    title: "React type",
    description:
      "A lightweight, simple, and intuitive, typing test single page application;",
    url: "https://adamjablonka.github.io/React-Type/",
  },
];

export const ProjectsPage = () => {
  return (
    <div className="Projects-div">
      <BackButton />
      <NavBar />
      <div>
        <div className="Projects-text">
          <samp>My recent work: </samp>
        </div>
        <div>
          {projects.map((project: project, index: number) => (
            <div>
              <ProjectPreview
                key={index}
                title={project.title}
                description={project.description}
                imageObject={project.imageObject}
                url={project.url}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
