import "../App.css";
import { BackButton } from "../components/BackButton";
import { ProgressiveRendering } from "../components/ProgressiveRendering";
import { NavBar } from "../components/NavBar";
import { nonHTMLStrings } from "../components/utils";

export const AboutPage = () => {
  return (
    <div className="About-div gridWrapper">
      <BackButton />
      <NavBar />
      <div className="About-name gridItem">
        <div className="Center-div">
          <ProgressiveRendering>
            <img
              src="/IMG_5610 6px bg.png"
              alt="portrait"
              className="portrait-photo"
              width={200}
            />
          </ProgressiveRendering>
        </div>
      </div>
      <div>
        <div>
          <div className="About-name">
            <samp>adam-jablonka</samp>
          </div>
          <div className="About-text gridItem">
            <samp>
              {nonHTMLStrings.gt}Bachelors in Computer Science with Minor in
              Mathematics from './queens-college'
            </samp>
          </div>
          <div className="About-text">
            <samp>{nonHTMLStrings.gt}Software Engineer @ ClearBlade</samp>
          </div>
          <div className="About-text">
            <samp>
              {nonHTMLStrings.gt}Quietly unraveling the threads of machine
              learning {nonHTMLStrings.amp} artificial intelligence.
            </samp>
          </div>
          <div className="About-text">
            <samp>
              {nonHTMLStrings.gt}Main experience in full stack development{" "}
              <b>JavaScript/Typescript</b>, <b>Python</b>, <b>HTML</b>,{" "}
              <b>CSS</b>, <b>SQL</b>, <b>React</b>, <b>Node.js</b>, <b>Git</b>,{" "}
              <b>AWS</b>.
            </samp>
          </div>
        </div>
      </div>
    </div>
  );
};
