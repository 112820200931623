import React from "react";
import "../App.css";
import { ClippyScene } from "./ClippyScene";

interface ProgressiveRenderingProps {
  children?: React.ReactNode;
}

export const ProgressiveRendering: React.FC<ProgressiveRenderingProps> = ({
  children,
}) => {
  return (
    <div className="dialup" style={{ zIndex: "5" }}>
      <div></div>
      {children}
    </div>
  );
};
