// @ts-nocheck
import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import "../App.css";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";

const desaturateShader = {
  uniforms: {
    tDiffuse: { value: null },
  },
  vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
  fragmentShader: `
      uniform sampler2D tDiffuse;
      varying vec2 vUv;
      void main() {
        vec4 color = texture2D(tDiffuse, vUv);
        float gray = dot(color.rgb, vec3(0.299, 0.587, 0.114));
        gl_FragColor = vec4(vec3(gray), color.a);
      }
    `,
};

const pixelationShader = {
  uniforms: {
    tDiffuse: { value: null },
    resolution: {
      value: new THREE.Vector2(200, 200),
    },
    pixelSize: { value: 1.5 }, // The size of the "pixels" in the effect, adjust as needed
  },
  vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
  fragmentShader: `
      uniform sampler2D tDiffuse;
      uniform vec2 resolution;
      uniform float pixelSize;
      varying vec2 vUv;
      void main() {
        vec2 dxy = pixelSize / resolution;
        vec2 coord = dxy * floor(vUv / dxy);
        vec4 color = texture2D(tDiffuse, coord);
        gl_FragColor = color;
      }
    `,
};

export const ClippyScene: React.FC = () => {
  const mountRef = useRef<HTMLDivElement>(null);
  let resetRotation = false;
  const targetRotation = { x: 0, y: 0 };

  useEffect(() => {
    if (!mountRef.current) return;

    // const sceneWidth = window.innerWidth;
    // const sceneHeight = window.innerHeight;
    const boxSize = 350;
    const sceneWidth = boxSize;
    const sceneHeight = boxSize;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      130,
      sceneWidth / sceneHeight,
      0.3,
      2000
    );
    camera.position.z = 2.325;
    camera.position.y = 0.5;
    camera.position.x = 0.5;
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
    scene.add(ambientLight);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(sceneWidth, sceneHeight);
    renderer.shadowMap.enabled = true;
    renderer.setClearColor(0x000000, 0);
    const composer = new EffectComposer(renderer);
    const renderPass = new RenderPass(scene, camera);
    composer.addPass(renderPass);
    const desaturationPass = new ShaderPass(desaturateShader);
    composer.addPass(desaturationPass);
    const pixelationPass = new ShaderPass(pixelationShader);
    composer.addPass(pixelationPass);
    // const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    // scene.add(ambientLight);
    // renderer.setSize(sceneWidth, sceneHeight);

    const floorGeometry = new THREE.PlaneGeometry(10000, 10000); // Large enough to appear infinite
    const floorMaterial = new THREE.ShadowMaterial();
    floorMaterial.opacity = 0.4;
    const floorMesh = new THREE.Mesh(floorGeometry, floorMaterial);
    floorMesh.rotation.x = -Math.PI / 2; // Rotate the floor to be horizontal
    floorMesh.receiveShadow = true; // Allow the floor to receive shadows
    floorMesh.position.y = -1.75;
    scene.add(floorMesh);

    let time = 0;

    mountRef.current.appendChild(renderer.domElement);

    const loader = new GLTFLoader();
    loader.load(
      "/models/ps1_cat/scene.gltf",
      (gltf) => {
        const model = gltf.scene;
        scene.add(model);
        model.position.set(0.5, 1.2, -1);

        model.traverse((node) => {
          if (node instanceof THREE.Mesh) {
            node.castShadow = true; // Allow each mesh in the model to cast shadows
          }
        });

        const cameraLight = new THREE.DirectionalLight(0xffffff, 2.5);
        cameraLight.position.set(
          camera.position.x,
          camera.position.y,
          camera.position.z
        );
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1.3);
        directionalLight.position.set(
          camera.position.x,
          camera.position.y + 5,
          camera.position.z
        );
        directionalLight.castShadow = true;
        // directionalLight.shadow.mapSize.width = 512; // Default is 512
        // directionalLight.shadow.mapSize.height = 512; // Default is 512

        scene.add(directionalLight);
        scene.add(cameraLight);
        const onMouseMove = (event: any) => {
          resetRotation = false;

          const rect = renderer.domElement.getBoundingClientRect();
          const mouseX = ((event.clientX - rect.left) / rect.width) * 2 - 1;
          const mouseY = -((event.clientY - rect.top) / rect.height) * 2 + 1;
          if (model) {
            targetRotation.y = (mouseX * Math.PI) / 7;
            targetRotation.x = (-mouseY * Math.PI) / 7;
          }
        };

        const onMouseLeave = (event: any) => {
          resetRotation = true;
          targetRotation.y = 0;
          targetRotation.x = 0;
        };

        renderer.domElement.addEventListener("mousemove", onMouseMove);
        renderer.domElement.addEventListener("mouseleave", onMouseLeave);

        directionalLight.target = model;
        cameraLight.target = model;

        // function updateLightPosition() {
        //   directionalLight.position.copy(camera.position);
        // }

        const animate = () => {
          requestAnimationFrame(animate);

          const floatAmplitude = 0.03; // Adjust the amplitude of the bobbing effect
          const floatFrequency = 0.3;
          model.position.y =
            -1.2 + floatAmplitude * Math.sin(floatFrequency * time);

          time += 0.05;

          // const currentScrollProgress = scrollYProgress.get();
          // // More controlled camera animation based on scroll progress
          // const angle = Math.PI * 2 * currentScrollProgress; // Full rotation over scroll
          // const distance = 5 + 15 * (1 - currentScrollProgress); // Closer as you scroll down

          // // Position the camera in a circular path around the Y-axis
          // camera.position.x = Math.sin(angle) * distance;
          // camera.position.y = Math.tan(angle) * distance; // Adjust based on your scene's needs
          // camera.position.z = Math.cos(angle) * distance;
          // camera.lookAt(model.position); // Focus on the model

          if (resetRotation && model) {
            model.rotation.x += (targetRotation.x - model.rotation.x) * 0.05;
            model.rotation.y += (targetRotation.y - model.rotation.y) * 0.05;

            if (
              Math.abs(model.rotation.x - targetRotation.x) < 0.01 &&
              Math.abs(model.rotation.y - targetRotation.y) < 0.01
            ) {
              resetRotation = false; // Stop the interpolation when the target is reached
            }
          }

          if (!resetRotation && model) {
            // model.rotation.x += (targetRotation.x)
            model.rotation.x += (targetRotation.x - model.rotation.x) * 0.05;
            model.rotation.y += (targetRotation.y - model.rotation.y) * 0.05;
          }

          // updateLightPosition();
          renderer.render(scene, camera);
          composer.render();
        };
        // model.position.set(0, 0, 0);
        animate();
        model.scale.set(1.5, 0.5, 0.5);
      },
      undefined, // This function is for progress, not needed here
      (error) => {
        console.error("An error happened while loading the model:", error);
      }
    );

    return () => {
      mountRef.current?.removeChild(renderer.domElement);
      // cleanup for listener functions
    };
  }, []); // Empty dependency array means this runs once when the component mounts

  return <div className="clippy-section" ref={mountRef} />;
};
