import "../App.css";

const backButton = "<-";

function Contact() {
  const name = "social-links";
  return (
    <div className="App">
      <header className="App-header">
        <samp className="simptip-position-top simptip-movable blinking-cursor">
          {name}
        </samp>
      </header>
      <body>
        <a href="/" className="links-container">
          <samp>{backButton}</samp>
        </a>
        <a
          href="https://www.linkedin.com/in/adam-jablonka/"
          className="links-container"
        >
          <samp>linkedin</samp>
        </a>
        <a
          href="mailto:ajjablonka@gmail.com"
          className="links-container simptip-position-bottom simptip-movable"
          data-tooltip="ajjablonka@gmail.com"
        >
          <samp>email</samp>
        </a>
        {/* <a
          href="https://drive.google.com/file/d/12_wKKeVSQ3g_8r5G_qct5nxp-0XgCP60/view?usp=sharing"
          className="links-container"
        >
          <samp>resume</samp>
        </a> */}
      </body>
    </div>
  );
}

export default Contact;
