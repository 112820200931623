import "../App.css";
import { nonHTMLStrings } from "./utils";

export const BackButton = () => {
  return (
    <div className="backButton">
      <a href="./">
        <samp>{nonHTMLStrings.backArr}</samp>
      </a>
    </div>
  );
};
