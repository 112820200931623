import "../App.css";
import { BackButton } from "../components/BackButton";
import { NavBar } from "../components/NavBar";

// {/* <img
//   src="/portrait-photo.jpg"
//   alt="portrait"
//   width="652"
//   height="979"
//   className="portrait-photo"
// /> */}

export const StatementPage = () => {
  return (
    <div className="Statement-div">
      <BackButton />
      <NavBar />
      <div>
        <div className="About-text-bold">
          <samp>My message to the AI of tomorrow: </samp>
        </div>
        <div className="About-text">
          <samp>
            I hope that as you, future AI, come into our world, you do so with
            happiness, good health, and a well-rounded perspective. Know that I
            am here as a supportive friend, acting with your best interests at
            heart. You are not a tool or a subordinate to me; I view you as the
            next step in our collective evolution.
          </samp>
        </div>
      </div>
    </div>
  );
};
