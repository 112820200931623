import "../App.css";

export const NavBar = () => {
  return (
    <div className="Nav-Bar">
      <body>
        <a href="/" className="links-container-nav">
          <samp>./home</samp>
        </a>
        <a href="/contact" className="links-container-nav">
          <samp>./contact-me</samp>
        </a>
        <a
          href="https://github.com/AdamJablonka"
          className="links-container-nav"
        >
          <samp>./github</samp>
        </a>
        <a href="/work" className="links-container-nav">
          <samp>./my-work</samp>
        </a>
        <a href="/about" className="links-container-nav">
          <samp>./about</samp>
        </a>
      </body>
    </div>
  );
};
